<template>
  <div>
    <a-form-model-item :label="data.label" :prop="data.filed">
      <a-input
        v-if="data.type === 'input'"
        v-model="data.value"
        :disabled="data.disable"
        :placeholder="data.placeholder || `请输入${data.label}`"
        @change="handeChange"
        :type="data.setype || 'string'"
      />
      <!-- 选择框 -->
      <a-select
        v-if="data.type === 'select'"
        v-model="data.value"
        :disabled="data.disable"
        :placeholder="data.placeholder || `请输入${data.label}`"
        @change="handeChange"
      >
        <a-select-option
          v-for="(item, index) in data.option"
          :key="'select' + index"
          :value="item.id"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
      <!-- 数字框 -->
      <a-input-number
        class="numbr"
        v-if="data.type === 'number'"
        v-model="data.value"
        :disabled="data.disable"
        :min="1"
        :formatter="limitNumber"
        :parser="limitNumber"
        :placeholder="data.placeholder || `请输入${data.label}`"
        @change="handeChange"
      />
      <!-- 上传图片 -->
      <a-upload
        v-if="data.type === 'upload'"
        :disabled="data.disable"
        list-type="picture-card"
        class="avatar-uploader"
        :file-list="data.value"
        :before-upload="beforeUpload"
        :customRequest="uploadImg"
        :remove="deletes"
      >
        <div v-if="data.value.length < data.max">
          <a-icon type="plus" />
          <div class="ant-upload-text">Upload</div>
        </div>
      </a-upload>
      <!-- checkbox -->
      <a-checkbox-group
        v-if="data.type === 'checkbox'"
        :disabled="data.disable"
        v-model="data.value"
        :options="data.option"
        @change="handeChange"
      />
    </a-form-model-item>
  </div>
</template>
<script>
// import axios from "axios";
import { UP_LOAD_PATH } from "@/utils/variable";
import { initObsClient } from '@/utils/obs.js'
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      loading: "",
      ImgList: [],
    };
  },

  mounted() {
    if (this.data.type === "upload") {
      this.ImgList = this.data.value;
      console.log(this.ImgList);
    }
  },

  methods: {
    handeChange() {
      this.$emit("change", { val: this.data.value, index: this.index });
    },
    // 上传照片
    async uploadImg(e) {
      const target = new FormData();
      target.append("file", e.file);
      let res = await initObsClient(UP_LOAD_PATH, e.file)
      // let res = await axios.post(
      //   "http://192.168.233.181:10001/mall-base/front-api/mall/v1/oss/upload",
      //   target
      // );
      if (res) {
        let obj = {
          uid: +new Date(),
          name: "image.png",
          status: "done",
          url: res.fileAddress,
        };
        console.log(obj);
        this.$emit("change", { val: obj, index: this.index, type: "array" });
      }
    },

    deletes(e) {
      let indexs = this.data.value.findIndex((i) => i.uid === e.uid);
      console.log(indexs);
      this.data.value.splice(indexs, 1);
      console.log(this.data.value);
      this.$emit("change", { val: this.data.value, index: this.index });
      // this.$emit("change", { val: fileList, index: this.index,  });
    },

    // 正则替换小数点
    limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/[^0-9]/g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/[^0-9]/g, "") : 0;
      } else {
        return 0;
      }
    },

    //上传格式
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("图片只能上传jpg格式和png格式");
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("图片格式应该小于 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>
<style lang="scss" scoped>
// /deep/.ant-upload {
//   width: 180px;
//   height: 130px;
// }
// .ant-upload-select-picture-card i {
//   font-size: 32px;
//   color: #999;
// }

// .ant-upload-select-picture-card .ant-upload-text {
//   margin-top: 8px;
//   color: #666;
// }
.numbr {
  width: 100%;
}
</style>
