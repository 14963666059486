<template>
  <div>
    <Breadcrumb :postion="crumb" />
    <a-spin :spinning="loading">
      <div class="home-container">
        <div class="home-content textleft">
          <div class="chenckOption">
            <div class="header">
              <span>审核意见</span>
              <div class="tags" v-if="state === 1">未认证</div>
              <!-- <div class="tags" v-if="state === 1 && !Description">未认证</div> -->
              <!-- <div class="tags fail" v-if="state === 1 && Description">
                认证失败
              </div> -->
              <div class="tags check" v-if="state === 2">审核中</div>
              <div class="tags pass" v-if="state === 3">已通过</div>
            </div>
            <div class="box">
              <div v-if="state === 1">
                <a-empty v-if="!Description">
                  <span slot="description">
                    您还未进行企业认证，暂无审核意见
                  </span>
                </a-empty>
                <span v-else>{{ Description }}</span>
              </div>

              <a-empty v-if="state === 2">
                <span slot="description" v-if="state === 2">
                  您的企业认证正在审核中
                </span>
              </a-empty>

              <span v-if="state === 3">审核通过</span>
            </div>
          </div>
        </div>
      </div>
      <div class="home-container">
        <div class="home-content textleft">
          <div class="chenckOption">
            <div class="header">
              <span>企业基础信息</span>
            </div>
            <div class="box">
              <From ref="company" :From="company" />
              <!-- <div @click="test">1212</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="home-container">
        <div class="home-content textleft">
          <div class="chenckOption">
            <div class="header">
              <span>个人信息</span>
            </div>
            <div class="box">
              <From ref="user" :From="user" />
            </div>
          </div>
        </div>
      </div>
    </a-spin>
    <div>
      <a-button
        type="primary"
        class="putBtn"
        @click="submit"
        :disabled="state === 2"
      >
        提交
      </a-button>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../components/breadcrumb.vue";
import From from "@/components/FromItem/From.vue";
import { UserAPI } from "@/api/User.js";
import { tips } from "@/utils/tips.js";
const checkPic = (rule, value) => {
  console.log(rule);
  return new Promise((resolve, reject) => {
    if (!value) {
      reject(new Error("请上传图片"));
    } else {
      resolve(value);
    }
  });
};
import Routerpush from "@/mixins/Routerpush";
export default {
  mixins: [Routerpush],
  data() {
    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      rule: {},
      plainOptions: ["Apple", "Pear", "Orange"],
      status: 2,
      //第一个配置
      company: {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        fromList: [
          {
            filed: "tradeCompany",
            label: "企业名称",
            value: "",
            require: true,
            placeholder: "",
            type: "input",
            disable: false,
            min: 0,
            max: 64,
          },
          {
            filed: "tradeCreditCode",
            label: "统一社会信用代码",
            value: undefined,
            require: true,
            placeholder: "",
            type: "input",
            disable: false,
            rules: [
              {
                pattern:
                  /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/,
                message: "统一社会信用代码格式不正确,请检查",
              },
            ],
          },
          {
            filed: "tradeAddress",
            label: "企业地址",
            value: "",
            require: true,
            placeholder: "",
            type: "input",
            disable: false,
            max: 128,
            min: 0,
          },
          {
            filed: "tradeSale",
            label: "公司上年度销售额",
            value: "",
            require: false,
            placeholder: "",
            type: "number",
            disable: false,
          },
          {
            filed: "tradeBusinessScope",
            label: "企业主营范围",
            value: "",
            require: true,
            placeholder: "",
            type: "input",
            disable: false,
            max: 32,
            min: 0,
          },
          {
            filed: "tradeType",
            label: "企业类型",
            value: "",
            require: true,
            placeholder: "",
            type: "select",
            disable: false,
            option: [
              { id: "0", name: "平台商" },
              { id: "1", name: "跨境电商" },
              { id: "2", name: "国内电商" },
              { id: "3", name: "外贸公司" },
              { id: "4", name: "内贸公司" },
              { id: "5", name: "其他" },
            ],
          },
          {
            filed: "tradeTelephone",
            label: "企业电话",
            value: "",
            require: false,
            placeholder: "",
            type: "input",
            max: 64,
            min: 0,
            // setype: "number",
            // rules: [
            //   {
            //     pattern:
            //       /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
            //     message: "手机号码格式不正确,请检查",
            //   },
            // ],
            disable: false,
          },
          {
            filed: "tradeWebsiteAddress",
            label: "官网地址",
            value: "",
            require: false,
            placeholder: "",
            type: "input",
            disable: false,
            max: 128,
            min: 0,
          },
          {
            filed: "tradeLicense",
            label: "公司营业执照",
            value: [],
            require: true,
            placeholder: "",
            rules: [{ validator: checkPic }],
            type: "upload",
            disable: false,
            max: 4,
          },
          {
            filed: "salesArea",
            label: "产品主要销售地",
            value: [],
            require: true,
            placeholder: "",
            type: "checkbox",
            disable: false,
            option: [],
          },
        ],
      },
      user: {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        fromList: [
          {
            filed: "tradeName",
            label: "姓名",
            value: "",
            require: true,
            placeholder: "",
            type: "input",
            disable: false,
            max: 32,
            min: 0,
          },
          {
            filed: "tradePosition",
            label: "职位",
            value: "",
            require: true,
            placeholder: "",
            type: "input",
            disable: false,
            max: 32,
            min: 0,
          },
          {
            filed: "tradePhone",
            label: "手机号",
            value: "",
            require: false,
            placeholder: "",
            type: "input",
            disable: true,
          },
          {
            filed: "tradeEmail",
            label: "邮箱",
            value: "",
            require: true,
            placeholder: "",
            type: "input",
            disable: false,
            max: 64,
            min: 0,
          },
          {
            filed: "tradeCallingCard",
            label: "名片",
            value: [],
            require: false,
            placeholder: "",
            type: "upload",
            rules: [{ validator: checkPic }],
            max: 4,
            disable: false,
          },
        ],
      },
      state: 1,
      loading: false,
      crumb: "资格认证",
      Description: "",
    };
  },
  components: {
    Breadcrumb,
    From,
  },

  mounted() {
    this.getUser();
    this.getArea();
  },

  methods: {
    // 提交
    submit() {
      this.$refs.company.submits().then((company) => {
        this.$refs.user.submits().then((user) => {
          this.loading = true;
          let checks = { ...company, ...user };
          //格式转化
          checks.tradeLicense = checks.tradeLicense
            .map((i) => {
              return i.url;
            })
            .toString();
          checks.tradeCallingCard = checks.tradeCallingCard
            .map((i) => {
              return i.url;
            })
            .toString();
          checks.salesArea = checks.salesArea.toString();
          UserAPI.checkUser(checks).then((res) => {
            this.loading = false;
            if (res.success) {
              tips(res, "资格认证提交");
              this.state = 2; //开启审核状态
              this.company.fromList.map((i) => {
                //认证中所有弹窗关闭
                i.disable = true;
                return i;
              });
              this.user.fromList.map((i) => {
                //认证中所有弹窗关闭
                i.disable = true;
                return i;
              });
            }
          });
        });
      });
    },
    //获取用户
    getUser() {
      UserAPI.getUser().then((res) => {
        console.log(res);
        if (res.success) {
          this.user.fromList.tradePhone = res.data.tradePhone;
          this.state = res.data.tradeState;
          this.Description = res.data.tradeReviewDescription;
          //   tradeLicense
          res.data.tradeLicense = !res.data.tradeLicense
            ? []
            : res.data.tradeLicense.split(",");
          //公司营业执照在渲染
          res.data.tradeLicense = res.data.tradeLicense.map((i) => {
            return {
              uid: +new Date(),
              name: "image.png",
              status: "done",
              url: i,
            };
          });
          //   名片
          res.data.tradeCallingCard = !res.data.tradeCallingCard
            ? []
            : res.data.tradeCallingCard.split(",");
          res.data.tradeCallingCard = res.data.tradeCallingCard.map((i) => {
            return {
              uid: +new Date(),
              name: "image.png",
              status: "done",
              url: i,
            };
          });
          //   产品主要出口销售地
          res.data.salesArea = !res.data.salesArea
            ? []
            : res.data.salesArea.split(",");
          let that = this;
          console.log(res.data);
          //认证中所有弹窗关闭
          // if (this.state === 2 || this.state === 3) {
          //渲染数据
          Object.keys(res.data).forEach(function (key) {
            that.company.fromList.map((i) => {
              if (i.filed === key) {
                i.value = res.data[key];
              }
              if (that.state === 2) {
                //认证中所有弹窗关闭
                i.disable = true;
              }
              return i;
            });
            that.user.fromList.map((i) => {
              if (i.filed === key) {
                i.value = res.data[key];
              }
              if (that.state === 2) {
                //认证中所有弹窗关闭
                i.disable = true;
              }
              return i;
            });
          });
          console.log("user", that.user);
          // this.company.fromList.map((i) => {
          //   i.disable = true;
          //   return i;
          // });
          // this.user.fromList.map((i) => {
          //   i.disable = true;
          //   return i;
          // });
          // }
        } else {
          this.$message.error("获取用户信息失败");
        }
      });
    },

    //获取地区
    getArea() {
      UserAPI.getArea().then((res) => {
        if (res.success) {
          this.company.fromList[9].option = res.data.map((i) => {
            return { label: i.dictValue, value: i.dictCode };
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.chenckOption {
  width: 100%;
  background: #fff;
  padding: 20px 0px;
  .header {
    border-bottom: 1px solid #efefef;
    padding: 0px 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .tags {
      border: 1px solid #999999;
      color: #999999;
      background: #efefef;
      border-radius: 5px;
      text-align: center;
      font-size: 12px;
      line-height: 24px;
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
    }
    .tags.check {
      color: #ff6f00;
      border: 1px solid #ff6f00;
      background: rgba(255, 111, 0, 0.2);
    }
    .tags.pass {
      color: #0abe5b;
      border: 1px solid #0abe5b;
      background: rgba(10, 190, 91, 0.2);
    }
  }
  .box {
    padding: 20px;
  }
  .fromsBox {
    display: flex;
    flex-wrap: wrap;
  }
  .froms {
    width: 47.5%;
    margin-right: 5%;
    &:nth-child(2n) {
      margin-right: 0%;
    }
  }
}
.check {
  color: #ff6f00;
  border: #ff6f00;
  background: rgba(255, 111, 0, 0.5);
}
.putBtn {
  width: 100px;
  margin: 20px;
}
.fail {
  color: #f5222d !important;
  background: #fff1f0 !important;
  border-color: #ffa39e !important;
}
</style>
