import axios from '@/config/http'
export const UserAPI = {
    checkUser(parmer){
        return axios.put(`/trade`,parmer)
    },

    getUser(){
        return axios.get(`/trade`)
    },

    getArea(){
        return axios.get(`/mall/v1/order/getDict`)
    }
}