<template>
  <div>
    <a-form-model
      ref="froms"
      :model="froms"
      :rules="rules"
      :label-col="From.labelCol"
      :wrapper-col="From.wrapperCol"
      class="fromsBox"
    >
      <Item
        v-for="(item, index) in AllList"
        :key="index"
        class="froms"
        :data="item"
        :index="index"
        @change="change"
      />
    </a-form-model>
  </div>
</template>
<script>
import Item from "./Item.vue";
export default {
  props: {
    From: {
      type: Object,
      default: () => {
        return {
          model: "company",
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
          fromList: [
            {
              filed: "name",
              label: "名字",
              value: "",
              require: true,
              rules: [
                {
                  required: true,
                  message: "Please input Activity name",
                },
              ],
              placeholder: "",
              type: "input",
              disable: false,
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      rules: {},
      froms: {},
      AllList: [],
    };
  },
  components: {
    Item,
  },
  watch: {
    From: {
      deep: true,
      handler() {
        // console.log("再次刷新", e);
        this.From.fromList.map((i) => {
          this.$set(this.froms, i.filed, i.value);
        });
      },
    },
  },

  mounted() {
    const datas = this.From;
    datas.fromList.map((i) => {
      let text = i.type === "input" ? "请输入" : "请选择";
      // 这一段对于from做一个校验
      if (i.rules) {
        i.rules.unshift({
          required: i.require,
          message: `${text}${i.label}`,
          trigger: "blur",
        });
      } else {
        i.rules = [
          {
            required: i.require,
            message: `${text}${i.label}`,
            trigger: "blur",
          },
        ];
      }

      if (i.max && i.type !== "upload") {
        i.rules.push({
          min: +i.min,
          message: `${i.label}在${i.min ? i.min : 0}个至${i.max}个之间`,
          max: +i.max,
          trigger: "blur",
        });
      }

      this.$set(this.rules, i.filed, i.rules);
      this.$set(this.froms, i.filed, i.value);
    });
    console.log("rules", this.rules);
    // 渲染数据放到自组建
    this.AllList = this.From.fromList;
  },

  methods: {
    submits() {
      return new Promise((resolve) => {
        this.$refs.froms.validate((valid) => {
          console.log(valid);
          if (valid) {
            resolve(this.froms);
          }
        });
      });
    },

    //子元素页面更改
    change(obj) {
      //   console.log(obj.val , this.From.fromList[obj.index].value);
      if (obj.type === "array") {
        let label = this.From.fromList[obj.index].filed;
        let newarr = this.From.fromList[obj.index].value;
        newarr.push(obj.val);
        this.$set(this.froms, label, newarr);
        this.getValToTree(label, newarr);
      } else {
        // console.log("非添加");
        let label = this.From.fromList[obj.index].filed;
        this.$set(this.froms, label, obj.val);
        this.getValToTree(label, obj.val);
      }
    },

    //对原有数据进行改变
    getValToTree(label, val) {
      this.AllList.map((i) => {
        if (i.filed === label) {
          i.value = val;
        }
        return i;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.fromsBox {
  display: flex;
  flex-wrap: wrap;
}
.froms {
  width: 47.5%;
  margin-right: 5%;
  &:nth-child(2n) {
    margin-right: 0%;
  }
}
</style>
