<template>
  <div class="breadcrumb-container">
    <div class="breadcrumb-content">
      <span class="breadcrumb-text">首页</span>
      <span class="breadcrumb-text split-line">/</span>
      <span class="breadcrumb-text current-breadcrumb">{{ postion }}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      property: "value",
    };
  },
  props: {
    postion: {
      type: String,
    },
  },
  mounted() {
    console.log(this.postion);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
.breadcrumb-container {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
}

.breadcrumb-content {
  width: 1200px;
  height: 40px;
  line-height: 40px;
  margin: auto;
  color: $fontGrey;
  font-size: 14px;
  text-align: left;
  .split-line {
    margin: 0 8px;
  }
  .current-breadcrumb {
    color: $fontBlack;
  }
}
</style>
